import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import ShortcodesNav from '../Elements/ShortcodesNav';

var bnrimg = require('./../../images/banner/5.jpg');

class Button extends React.Component {
    render() {
        return (
            <>

                <div className="page-content">
                   
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        <div className="container">
                            <div className="row">
                                
                                <div className="col-md-12 col-sm-12">

                                    <div className="m-b50">
                                        {/* TITLE START */}
                                        <div className="section-head">
                                            <div className="mt-separator-outer separator-center">
                                                <div className="mt-separator">
                                                    <h2 className="text-black text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nossas</span> Redes</h2>
                                                </div>
                                                <div className="section-content">
                                                    <NavLink to={{pathname: "http://www.saelt.com.br"}} target="_blank" className="m-b20 site-button-secondry btn-effect text-uppercase"><i className="fa fa-link" />Site</NavLink>
                                                </div>
                                                <div className="section-content">
                                                    <NavLink to={{pathname: "https://wa.me/message/5YXFY6TSKVEUH1"}} target="_blank" className="m-b20 site-button-secondry btn-effect text-uppercase"><i className="fa fa-whatsapp" />WhatsApp</NavLink>
                                                </div>
                                                <div className="section-content">
                                                    <NavLink to={{pathname: "https://www.instagram.com/saeltbr/"}} target="_blank" className="m-b20 site-button-secondry btn-effect text-uppercase"><i className="fa fa-instagram" />Instagram</NavLink>
                                                </div>
                                                <div className="section-content">
                                                    <NavLink to={{pathname: "https://www.facebook.com/saeltbr/"}} target="_blank" className="m-b20 site-button-secondry btn-effect text-uppercase"><i className="fa fa-facebook" />Facebook</NavLink>
                                                </div>
                                                <div className="section-content">
                                                    <NavLink to={{pathname: "https://www.linkedin.com/in/saeltbr/"}} target="_blank" className="m-b20 site-button-secondry btn-effect text-uppercase"><i className="fa fa-linkedin" />LinkedIn</NavLink>
                                                </div>
                                                <div className="section-content">
                                                    <NavLink to={{pathname: "https://www.twitter.com/saeltbr/"}} target="_blank" className="m-b20 site-button-secondry btn-effect text-uppercase"><i className="fa fa-twitter" />Twitter</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    {/* TITLE END */}
                                
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
};

export default Button;