import React from 'react';

var img1 = require('./../../images/background/bg-6.png');
var img2 = require('./../../images/background/line.png');

class AboutSummary extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/masonary.js');
      
    };
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b80 bg-dark bg-repeat square_shape2 inner-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator text-white">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Sobre</span> nós</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content our-story">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic4.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase m-b20">2022</h4>
                                        <p>Um novo plano de de negócios foi implantado para estabelecer metas de crescimento e relacionamento de confiança. 
                                            A identidade da empresa foi remodelada com foco na entrega de valor de negócio. Um novo conceito de serviços foi introduzido
                                            para fornecer ao setor elétrico industrial local, soluções técnicas robustas e eficientes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic7.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="text-uppercase m-b20">2021</h4>
                                        <p>A Empresa especializou sua equipe em gestão e controle de manutenção industrial, agregando em seu catálogo de serviços,
                                            soluções em gerenciamento de manutenção. Iniciou o desenvolvimento do sistema SYSMAIN, sistema para controle de manutenção,
                                            gestão de ordens de serviços e ativos de operação. A finalidade macro do sistema foi de prover soluções simples e flexíveis, para proporcionar
                                            novas soluções aos empreendimentos locais.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic6.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                                        <h4 className="text-uppercase m-b20">2020</h4>
                                        <p>Em um momento de desaquecimento comercial global em virtude da pandemia, a Empresa estabeleceu estratégias para garantir
                                            o fornecimento de soluções e relacionamento de confiança com nossos clientes. Obedecendo os protocolos de saúde, investiu
                                            na capacitação técnica da equipe, proporcionado novas oportunidades e ideias de negócio. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic5.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                                        <h4 className="text-uppercase m-b20">2017</h4>
                                        <p>A Selt Soluções Elétricas foi criada para prestar serviços de engenharia, oferecidos no setor elétrico
                                            do estado de Roraima. A Empresa iniciou sua atuaçao com autoria e execução de projetos de instalações e subestações
                                            elétricas. Em virtude da carência local em soluções de maior qualidade técnica e especialização, a gestão estratégica
                                            da Saelt, passou a investir em capacitação e criação de soluções mais robustas para os negócios do estado.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutSummary;