import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCompany extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/s-1.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Soluções com foco na geração valor de negócio para nossos clientes.</h3>
                                            <p>Todas as soluções desenvolvidas em nossa Empresa são definidas considerando sempre o melhor custo x benefício para seu empreendimento, assim como a capacidade e viabilidade da área de negócio.
                                                Valorizamos seu investimento, pensando em soluções que agreguem valor técnico e de negócio para sua empresa, proporcionando a análise técnica e melhoria contínua.
                                                A padronização e documentação de seus ativos, tornam o processo de gestão e controle de sua empresa mais eficiente e transparente, evidenciando falhas e facilitando as correções e melhorias.
                                            </p>
                                            <div className="call-for-quote-outer">
                                                <div className="call-quote">
                                                    <span>Entre em contato:</span>
                                                    <h4>(95) 99169-8816</h4>
                                                </div>
                                                <div className="call-estimate bg-dark">
                                                <NavLink to={"/contactus"} className="site-button-secondry btn-effect">Solicite um orçamento</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutCompany;