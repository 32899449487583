import React from 'react';
import { NavLink } from 'react-router-dom';

class Faq extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white inner-page-padding">
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                
                                <div className="col-md-12 col-sm-12">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="mt-separator-outer separator-center">
                                            <div className="mt-separator">
                                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nossos</span> Sistemas</h2>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mt-icon-box-wraper p-a25 m-b50 onhover-box-shadow bg-gray center">
                                                <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center ">
                                                    <span className="icon-cell ">
                                                        <img src={require('../../images/sysmain.png')} alt="sysmain"/>
                                                    </span>
                                                </div>
                                                <div className="icon-content text-black">
                                                    <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">sysmain</h4>
                                                    <p>Sistema de gerenciamento de manutenção e controle de ativos.</p>
                                                    <NavLink to="#" className="site-button-link yellow" data-hover="Read More">Saiba mais <i className="fa fa-angle-right arrow-animation" /></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mt-icon-box-wraper p-a25 m-b50 onhover-box-shadow bg-gray center">
                                                <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                                    <span className="icon-cell">
                                                    <img src={require('../../images/sysproj.png')} alt="sysmain"/>
                                                    </span>
                                                </div>
                                                <div className="icon-content text-black">
                                                    <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">sysproj</h4>
                                                    <p>Sistema de gestão de projetos, obras e serviços.</p>
                                                    <NavLink to="#" className="site-button-link yellow" data-hover="Read More">Saiba mais <i className="fa fa-angle-right arrow-animation" /></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FAQ */}
                </div>
            </>
        );
    }
};

export default Faq;