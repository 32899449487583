import React from 'react';
import { NavLink } from 'react-router-dom';

var img1 = require('./../../images/background/bg-4.png');
var img2 = require('./../../images/background/bg-site.png');

class OurValue3 extends React.Component {
    
    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };

    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-gray bg-no-repeat bg-bottom-right mobile-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        <div className="section-content ">
                            <div className="row">
                                <div className="col-md-12 col-sm-12  m-b30">
                                    <div className="brochur bg-dark p-a20">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="p-a30 bg-primary bg-bottom-right" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                                    <div className="text-black">
                                                        <h4 className="mt-tilte m-t0">Catálogo de Serviços</h4>
                                                        <p>Typefaces and layouts, and in appearance of different general the content of dorem ipsum dolor sit amet. </p>
                                                        <NavLink to="#" className="site-button-secondry btn-half"><span> Download </span></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="text-white p-r15">
                                                    <h4 className="mt-tilte">Entre em contato!</h4>
                                                    <p>Caso necessite de mais informações entre em contato com nossa equipe. <br/> 
                                                    Nossa equipe responderá sua solicitação em breve. <br/>
                                                    Ficaremos felizes em atender sua solicitação.</p>
                                                    <NavLink to="/contactus" className="site-button btn-effect">Entre em contato</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default OurValue3;