import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Slider from './../Elements/Slider';
import Specialization from './../Elements/Specialization';
import About from './../Elements/About';
import OurValue from './../Elements/OurValue';
import OurMission from './../Elements/OurMission';
import OurServices from './../Elements/OurServices';
import Callus from './../Elements/Callus';
import OurProject from './../Elements/OurProject';
import Blogs from './../Elements/Blogs';
import Testimonials from './../Elements/Testimonials';
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';
import FloatingWhatsApp from 'react-floating-whatsapp';
import avatarResource from '../../images/avatar.png';

const saelt = {
    src: avatarResource,
    alt: 'avatar',
  };

class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider />
                    <Specialization />
                    <About />
                    <OurMission />
                    <OurServices />
                    <Callus />
                    <CookieConsent
                        location="bottom"
                        buttonText="Entendo a política de cookies"
                        cookieName="saeltCookies"
                        expires={150}
                        
                    >

                        Este site usa cookies para melhorar a sua experiência online. 
                        As informações detalhadas sobre o uso de cookies deste site estão disponíveis em nosso Aviso de Privacidade. 
                        Ao continuar acessando o site você concorda com a nossa política de uso de cookies.
                        <span style={{ fontSize: "10px" }}><br/><Link style={{color: "#999999"}}> Mais informações.</Link></span>
                    
                    </CookieConsent>
                    <FloatingWhatsApp 
                        phoneNumber='+5595991698816'
                        accountName='Saelt'
                        statusMessage='Soluções Elétricas'
                        chatMessage='Olá! Posso ajudar?'
                        placeholder='Digite sua mensagem..'
                        avatar = {saelt.src}

                    />
                </div>
                <Footer />
            </>
        );
    };
};

export default Home;